import { toast } from 'react-toastify';
import { downloadFile } from '../utils/fileUtils';
import { useState } from 'react';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import Dialog from '@mui/material/Dialog';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import PdfIcon from '@mui/icons-material/PictureAsPdf';
import api from '../api';

export default function TicketPdfSelectorDialog({ orderId, onClose }) {
  const [selectedType, setSelectedType] = useState('ONLINE');
  const [isLoading, setIsLoading] = useState(false);

  const onCloseInternal = (event, reason) => {
    if (reason !== 'backdropClick') {
      setSelectedType('ONLINE');
      onClose();
    }
  };

  const onConfirmInternal = () => {
    setIsLoading(true);
    api
      .get(`/orders/${orderId}/tickets?type=${selectedType}`, { responseType: 'blob' })
      .then((res) => {
        const data = res.data;
        setIsLoading(false);
        downloadFile(data, 'application/pdf', `tickets_order_${orderId}.pdf`);
        onCloseInternal();
      })
      .catch((err) => {
        setIsLoading(false);
        toast.error('An error occured please try again!', { position: toast.POSITION.BOTTOM_RIGHT });
      });
  };

  return (
    <Dialog fullWidth maxWidth="xs" open={!!orderId} onClose={onCloseInternal} onBackdropClick={false}>
      <DialogTitle gutterBottom>
        <Box display="flex" alignItems="center">
          <PdfIcon sx={{ marginRight: 1 }} />
          {`Download #${orderId} ticket(s)`}
        </Box>
        <IconButton
          aria-label="close"
          onClick={onCloseInternal}
          sx={{
            position: 'absolute',
            right: 8,
            top: 10,
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <Divider />
      <DialogContent>
        <TextField
          value={selectedType}
          onChange={(event) => setSelectedType(event.target.value)}
          fullWidth
          select
          label="Type"
        >
          <MenuItem selected value={'OFFLINE'}>
            Offline
          </MenuItem>
          <MenuItem value={'ONLINE'}>Online</MenuItem>
        </TextField>
      </DialogContent>
      <DialogActions>
        <Button disabled={isLoading} onClick={onConfirmInternal} variant="contained">
          Download
          {isLoading && <CircularProgress size={20} sx={{ ml: 1, color: 'white' }} />}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
